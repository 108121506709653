.confirmation {
  span {
    background-color: #cbf4c9;
    color: #0e6245;
    padding: 2px;
  }
}
tbody{
  .selected-row{
    background: #e4e4e4;
  }
}
.modal{
  &.detail{
    background: #e4e4e4;
    .modal-lg{
      min-width: 65%;
    }
  }
  .modal-body{
    .spinner-border{
      font-size: 10px;
      width: 16px;
      height: 16px;
    }
  }
}

.orange{
  cursor: pointer;
  border: none;
  background: none;
  .spinner-border{
    font-size: 10px;
    width: 15px;
    height: 15px;
  }
}

#assessment-container {
  .container {
    max-width: 100%;
    height: 80vh;
    overflow-y: auto;
    input[type='checkbox'] {
      cursor: pointer;
      height: 25px;
      width: 25px;
      margin-right: 10px;
    }
    #assessment-row {
      width: 100%;
      margin: 0 auto;
      #assessment-box {
        #assessment-form {
          margin-top: 25px;
          margin-bottom: 25px;
          .section{
            margin-bottom: 40px;
            h5{
              margin-top: 25px;
              font-weight: bold;
            }
            .section-content{
              border: 1px solid #ccc;
              background-color: #fff;
              padding: 25px;
              .form-group{
                label{
                  margin: 0;
                  font-size: 16px;
                  text-transform: inherit;
                }
                .radio-inline{
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  cursor: pointer;
                  color: #444;
                  pointer-events:none;
                  opacity:0.9;
                  input[type='radio'] {
                    cursor: pointer;
                    box-sizing: border-box;
                    height: 28px;
                    width: 28px;
                    border-radius: 28px;
                  }
                }
                &.checklist{
                  padding-left: 20px;
                  span{
                    margin-left: 15px;
                  }
                }
                input[type='checkbox'] {
                  cursor: pointer;
                  height: 25px;
                  width: 25px;
                  margin-right: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}

#visit-container {
  width: 100%;
  .container {
    max-width: 100%;
    height: 80vh;
    overflow-y: auto;
    #visit-row {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 0 auto;
      .visit-form {
        .section {
          margin-bottom: 40px;

          h5 {
            margin-top: 25px;
            font-weight: bold;
          }

          .info-text {
            color: #444;
            font-size: 14px;
          }

          .section-content {
            border: 1px solid #ccc;
            background-color: #fff;
            padding: 25px;

            .form-group {
              label {
                margin: 0;
                font-size: 16px;
                text-transform: inherit;
              }

              .radio-inline {
                display: flex;
                flex-direction: row;
                align-items: center;
                cursor: pointer;
                color: #444;
                pointer-events:none;
                opacity:0.9;
                input[type='radio'] {
                  cursor: pointer;
                  box-sizing: border-box;
                  height: 28px;
                  width: 28px;
                  border-radius: 28px;
                }
              }
              &.checklist {
                padding-left: 20px;
                span {
                  margin-left: 15px;
                  font-size: 14px;
                }
              }

              input[type='checkbox'] {
                cursor: pointer;
                height: 25px;
                width: 25px;
                margin-right: 10px;
                pointer-events:none;
                opacity:0.9;
              }
            }

            input[type='text'] {
              border: 1px solid #444;
              pointer-events:none;
              opacity:0.9;
              &:focus {
                outline: none;
                box-shadow: none;
                border: 2px solid #444;
              }
            }

            select {
              border: 1px solid #444;
              pointer-events:none;
              opacity:0.9;
              &:focus {
                outline: none;
                box-shadow: none;
                border: 2px solid #444;
              }
            }
            .flex-basis {
              div {
                flex-basis: 35%;
                flex-grow: 0;
              }
            }
          }
          .PhoneInput {
            .PhoneInputCountry {
              display: none;
            }
            .PhoneInputInput {
              margin-bottom: 0;
              border: 1px solid #444;
              height: calc(1.5em + 0.75rem + 2px);
              padding: 0.375rem 0.75rem;
              font-size: 1rem;
              font-weight: 400;
              line-height: 1.5;
              color: #495057;
              background-color: #fff;
              border-radius: 0.25rem;
              pointer-events:none;
              opacity:0.9;
              &:focus {
                background-color: #fff;
                outline: 0;
                box-shadow: none;
                border: 2px solid #444;
              }
            }
          }

          .insurance-upload-container {
            display: flex;
            flex-direction: row;
            margin: 0 auto;
            width: 60%;
            justify-content: space-between;
            img{
              cursor: pointer;
              width: 80%;
            }
          }
        }

        .or-block {
          position: relative;

          span {
            display: block;
            position: absolute;
            top: -15px;
            left: 47%;
            background: #fff;
            padding: 5px 10px;
            font-weight: bold;
          }
        }
      }
    }
  }
}
.primary-outline{
  border-color: #007bff;
  color: #007bff;
  &:focus{
    background-color: #007bffe0;
  }
}
.fa{
  &.fa-times-circle{
    color: red;
    margin-left: 5px;
    cursor: pointer;
    font-size: 20px;
    padding-left: 5px;
  }
}

.confirmation-code-label{
  padding: 2px;
  .content{
    font-size: 18px;
    &.code{
      font-size: 23px;
      text-decoration: underline;
    }
  }
}

.location-appointments{
  .date-selection{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .date-div{
      display: flex;
      flex-direction: column;
      label{
        margin-bottom: 2px;
        font-size: 14px;
      }
    }
  }
  .time-selection{
    display: flex;
    flex-direction: row;
    .time-div{
      display: flex;
      flex-direction: column;
      margin-right: 10px;
      label{
        margin-top: 10px;
        margin-bottom: 2px;
        font-size: 14px;
      }
    }
  }
}

.primary{
  color: #007bff;
  cursor: pointer;
  border: none;
  background: none;
  .spinner-border{
    font-size: 10px;
    width: 15px;
    height: 15px;
  }
}

.available {
  background-color: #cbf4c9;
  color: #0e6245;
  padding: 2px;
}
.booked {
  background-color: #6c757d61;
  color: #6c757d;
  padding: 2px;
}
.reserved {
   background-color: #007bff59;
   color: #007bff;
   padding: 2px;
}
.disabled {
   background-color: #f0d6d0;
   color: #d93110;
   padding: 2px;
}

.location-filter{
  height: 30px;
  font-size: 14px;
  margin-left: 15px;
  padding: 0 5px;
}
.add-location-body{
  height: 65vh;
  overflow: auto;
  overflow-x: hidden;
}
.active-circle{
  color: #1df611;
  padding-right: 3px;
}
.cancelled-circle{
  color: red;
  padding-right: 3px;
}
