.nav-tabs {
  .nav-item{
    &.active {
      background-color: #098180b3;
    }
    &.nav-link{
      color: #495057;
    }
  }
}
.faq{
  .ql-editor{
    min-height: 280px !important;
  }
}
.vendor-table {
  .vendor-table-body {
    .table{
      thead{
        .action{
          width: 15% !important;
        }
      }
      tbody {
        tr {
          td{
            text-transform: inherit;
            max-width: 100px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
