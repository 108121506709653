.notifications-container{
  .notification {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 10px 10px 5px;
    margin-bottom: 10px;
    border: 1px solid #8080805c;
    align-items: center;
    &:first-child{
      margin-top: 10px;
    }
    .icon{
      padding-left: 10px;
      padding-right: 10px;
    }
    .date{
      float: right;
      color: #444;
      font-size: 12px;
    }
  }
  .spinner-div{
    text-align: center;
    font-size: 14px;
    .spinner-border {
      width: 25px;
      height: 25px;
    }
  }
}
