.fa.fa-print{
  opacity: 0.5;
  cursor: pointer;
  &:hover{
    opacity: 1;
  }
}
.pathogen{
  .detected{
    color: #d93110;
  }
}
.accession-label{
  padding: 40px 10px 10px 10px;
  .logo{
    border: 0;
    padding: 0;
    width: 115px;
    height: 23px;
  }
  .content{
    font-size: 15px;
  }
}
