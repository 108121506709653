@import "../Styles/variables.scss";

//profile css
.cart-section{
  .cart-section-head{
    margin-bottom: 30px;
    h2{
      margin: 0px;
      color: #000000;
      text-transform: uppercase;
      font-size: 35px;
      font-weight: 700;
    }
    p{
      margin-bottom: 0px;
      color: #000000;
      font-size: 18px;
    }
  }

//checkout-body
.profile-body{
  .profile-form{
    .form-group{
      .form-control{
        border-color: #D1D1D1;
        border-radius: 0px;
        font-size: 12px;
        //text-transform: uppercase;
        color: #000;
        height: 36px;
        &:hover, &:focus{
          border-color: #D1D1D1;
        }
      }
    }
    .checkout-btn{
      text-align: center;
      margin: 30px 0px 20px;
      button{
        border: 1px solid #E25E52;
        color: #E25E52;
        background-color: transparent;
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
        padding: 6px 20px;
        min-width: 250px;
        &:hover{
          background-color: #E25E52;
          color: #fff;
        }
      }
    }
  }
    .row:not(:last-child){
      border-right: 1px solid grey;
    }
  }
}

img{
  border: 1px solid #d1d1d1;
  width: 50%;
  height: 50%;
  margin: 0px auto 20px;
}

.upload-btn{
  button{
    border: 1px solid $color-main;
    color: $color-main;
    background-color: transparent;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    padding: 6px 20px;
    &:hover, &:focus{
      background-color: $color-main;
      color: #fff;
    }
  }
  .secondary-outline-btn{
    border: 1px solid #6c757d;
    color: #6c757d;
    background-color: transparent;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    padding: 6px 20px;
    min-width: 150px;
    &:hover, &:focus{
      background-color: #6c757d;
      color: #fff;
    }
  }

  .danger-outline-btn{
    border: 1px solid #dc3545;
    color: #dc3545;
    background-color: transparent;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    padding: 6px 20px;
    min-width: 140px;
    &:hover, &:focus{
      background-color: #dc3545;
      color: #fff;
    }
  }
}

//profile
.profile{
  //profile-picture
  .profile-picture{
    text-align: center;
    border: 1px solid #D1D1D1;
    padding: 15px 15px 40px;
    .profile-picture-head{
      h3{
        color: #000000;
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 40px;
        text-transform: uppercase;
      }
    }
    .user-profile-img{
      border: 1px solid #d1d1d1;
      border-radius: 100%;
      overflow: hidden;
      width: 150px;
      height: 150px;
      display: flex;
      justify-content: center;
      margin: 0px auto 20px;
      img{
        width: 100%;
      }
    }
    .upload-btn{
      button{
        border: 1px solid $color-main;
        color: $color-main;
        background-color: transparent;
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
        padding: 6px 20px;
        &:hover, &:focus{
          background-color: $color-main;
          color: #fff;
        }
      }
    }
  }

}
@media only screen and (max-width: 767px) {
.profile {
  .profile-picture {
    margin-bottom: 30px;
  }
}
.cart-section {
  margin-top: 30px;
}
}
@media screen and (max-width: 992px) and (min-width: 768px){
  .profile {
    .profile-picture {
      margin-bottom: 30px;
    }
  }
  }
