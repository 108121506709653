@import "../Styles/variables.scss";
//body
body{
  background-color: #f8f8f8;
  .form-control{
    &:focus{
      outline: none;
      box-shadow: none;
    }
  }
  button{
    &:focus{
      outline: none;
      box-shadow: none;
    }
  }
  a{
    &:hover{
      text-decoration: none;
    }
  }
}

//forms
.form-group{
  label{
    color: #000000;
    font-size: 13px;
    margin-bottom: 8px;
    font-weight: 500;
    text-transform: uppercase;
    sup{
      color: #E25E52;
    }
  }
  .form-control{
    border: 1px solid #D1D1D1;
    height: 40px;
    border-radius: 0px;
    font-size: 13px;
  }
  .form-textarea{
    min-height: 120px;
    resize: none;
  }
}

//custom-radio-input
.custom-radio-input {
  display: flex;
  p {
    display: flex;
    align-items: center;
    span {
      font-size: 20px;
      input {
        margin-left: 5px;
      }
      input[type='radio']:after {
        width: 15px;
        height: 15px;
        border-radius: 15px;
        top: -9px;
        left: -1px;
        position: relative;
        background-color: white;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 1px solid #E25E52;
    }

    input[type='radio']:checked:after {
        width: 15px;
        height: 15px;
        border-radius: 15px;
        top: -9px;
        left: -1px;
        position: relative;
        background-color: #E25E52;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 1px solid #E25E52;
    }
    }
  }
}

//colors
.orange{
  color: #E25E52;
}
.green{
  color: #098180;
}

.black{
  color: #000;
}

//buttons
.orange-outline-btn{
  border: 1px solid $color-main;
  color: $color-main;
  background-color: transparent;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 6px 20px;
  min-width: 150px;
  &:hover, &:focus{
    background-color: $color-main;
    color: #fff;
  }
}

.secondary-outline-btn{
  border: 1px solid #6c757d;
  color: #6c757d;
  background-color: transparent;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 6px 20px;
  min-width: 150px;
  &:hover, &:focus{
    background-color: #6c757d;
    color: #fff;
  }
}

.danger-outline-btn{
  border: 1px solid #dc3545;
  color: #dc3545;
  background-color: transparent;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 6px 20px;
  min-width: 140px;
  &:hover, &:focus{
    background-color: #dc3545;
    color: #fff;
  }
}

.hyper-link-btn{
  border: none;
  color: #000;
  background-color: transparent;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 0px;
  &:hover, &:focus{
    color: #E25E52;
  }
}

.orange-btn{
  border: 1px solid #E25E52;
  color: #fff;
  background-color: #E25E52;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 6px 20px;
  min-width: 150px;
  &:hover, &:focus{
    background-color: #fff;
    color: #E25E52;
  }
}

//main-body
.main-body{
  display: flex;
  flex-wrap: wrap;
  background-color: #F8F8F8;
  .main-content{
    width: calc(100% - 250px);
    margin-left: 250px;
    z-index: 1;
    .main-header{
      background-color: #FFFFFF;
      padding: 15px;
      margin: 15px 0px;
      border:0.5px solid #ced4da;
      .header-top-left{
        display: flex;
        align-items: center;
        justify-content: left;
        .toggle-btn{
          margin-right: 15px;
          display: none;
          button{
            border: 0px;
            background-color: $color-main;
            color: #fff;
            padding: 5px 10px;
          }
        }
        .header-top-search{
          display: flex;
          align-items: center;
          position: relative;
          width: 100%;
          button{
            background-color: transparent;
            color: $color-main;
            border: none;
            padding: 0px;
            height: 25px;
            width: 25px;
            padding-right: 10px;
          }
          .form-control{
            border-radius: 0px;
            border-color: #00000029;
            border: 0px;
            height: 30px;
            border-bottom: 1px solid #777777;
            padding-left: 0px;
            font-size: 14px;
            font-weight: 500;
            background-color: transparent;
          }
        }
      }



      .header-top-menu{
        ul{
          margin: 0px;
          padding: 0px;
          display: flex;
          align-items: center;
          margin-top: 6px;
          li{
            list-style: none;
            margin: 0px 15px;
            a{
              color: #000000;
              font-size: 16px;
              i{}
              &.header-addtocart-item{
                position: relative;
                span{
                  background-color: $color-main;
                  font-size: 10px;
                  position: absolute;
                  top: -7px;
                  right: -11px;
                  color: #fff;
                  border-radius: 100%;
                  min-width: 20px;
                  min-height: 20px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
              }
            }
          }
        }
      }
    }
    .main-footer{
      text-align: right;
      margin-top: 10px;
      p{
        margin-bottom: 0px;
        color: #000000;
        font-size: 13px;
        margin-bottom: 8px;
        font-weight: 500;
      }
    }
    .admin-card{
      .admin-card-head{
        h2{
          color: #000000;
          text-transform: uppercase;
          font-size: 18px;
          margin-bottom: 15px;
        }
      }
      .admin-card-box{
        background-color: #FFFFFF;
        padding: 15px;
        border: 0.5px solid #ced4da;
      }
    }
  }
}

//Custom Radio Buttons
.custon-radio {
  display: block;
  position: relative;
  padding-right: 23px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
  user-select: none;
  color: #989898 !important;
  margin-right: 15px;
}
.custon-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.custon-radio .checkmark {
  position: absolute;
  top: 1px;
  right: 0;
  height: 18px;
  width: 18px;
  background-color: #eee;
  border-radius: 50%;
}

.custon-radio input:checked ~ .checkmark {
  background-color: #E25E52;
}
.custon-radio .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.custon-radio input:checked ~ .checkmark:after {
  display: block;
}
.custon-radio .checkmark:after {
  left: 6px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.custom-radio-inline{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.label-radio-inline{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

//Custom Radio Buttons end

//vendor-table
.vendor-table{
  .vendor-table-head{
    .entries-drop{
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      label{
        color: #000000;
        font-size: 13px;
        font-weight: 500;
        text-transform: uppercase;
        margin-right: 10px;
        margin-bottom: 0px;
      }
      .entries-controls{
        border: 1px solid #D1D1D1;
        &:focus{
          outline: none;
          box-shadow: none;
        }
      }
      p{
        color: #000000;
        font-size: 13px;
        margin-bottom: 0px;
        font-weight: 500;
        text-transform: uppercase;
        margin-left: 5px;
      }
    }
    .vendor-table-head-right{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-wrap: wrap;
      .vendor-table-search{
        margin-bottom: 15px;
        .form-control{
          border: 1px solid #D1D1D1;
          border-radius: 0px;
          font-size: 13px;
          text-transform: uppercase;
          font-weight: 500;
          width: auto;
          height: 25px;
        }
      }
      .download-statement-btn{
        margin-bottom: 15px;
      }
    }
  }
  .vendor-table-body{
    .table{
      thead{
        background-color: rgba(226, 94, 82, 0.1);
        tr{
          td{
            color: #989898;
            text-transform: uppercase;
            font-weight: 500;
            font-size: 13px;
            vertical-align: middle;
            white-space: nowrap;
          }
        }
      }
      tbody{
        tr{
          &.expiring{
            background: #e2e3e5;
          }
          td{
            color: #000000;
            text-transform: uppercase;
            font-weight: 500;
            font-size: 13px;
            vertical-align: middle;
            white-space: nowrap;
            p{
              margin-bottom: 0px;
            }
            a{
              color: #E25E52;
              &:hover{
                color: #000000;
              }
            }
            .vendor-table-btn{
              .orange-outline-btn{
                min-width: auto;
                font-size: 12px;
                text-transform: capitalize;
                padding: 2px 20px;
              }
            }
          }
        }
      }
      .vendor-table-check{
        .custon-radio{
          margin: 0px;
          padding: 0px;
          display: block;
          .checkmark{
            left: 0;
            top: -8px;
            border: 1px solid #989898;
            border-radius: 0px;
            background-color: #fff;
            &:after{
              border-color:#e25e52;
            }
          }
        }
      }
    }
  }
}


//paymentdetails-modal
.paymentdetails-modal{
  background-color: #00000059;
  .modal-dialog{
    .modal-content{
      border-radius: 0px;
      border: 0px;
      .modal-header{
        border-bottom: 0px;
        position: relative;
        padding: 25px 20px 5px;
        .paymentdetails-heading{
          text-align: center;
          width: 100%;
          h4{
            text-transform: uppercase;
            font-weight: 500;
            font-size: 18px;
            margin-bottom: 0px;
          }
        }
        .close{
          position: absolute;
          top: 22px;
          right: 15px;
          padding: 0px;
          margin: 0px;
          color: #000;
          opacity: 1;
          &:hover{
            opacity: 0.7;
          }
        }
      }
      .modal-body{
        padding-bottom: 25px;
        .paymentdetails-table{
          .table{
            tbody{
              tr{
                td{
                  border: 1px solid #f3f2f2;
                  h5{
                    text-transform: uppercase;
                    font-weight: 500;
                    font-size: 14px;
                    margin-bottom: 3px;
                    white-space: nowrap;
                    color: #000;
                  }
                  p{
                    font-size: 12px;
                    margin-bottom: 0px;
                    color: #000;
                    span{
                      text-transform: uppercase;
                      display: block;
                      &.green{
                        color: #19BF00;
                      }
                    }

                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.form-field-error{
  color: red;
  font-size: 12px;
}


@media only screen and (max-width: 1024px) {

  //main-body
  .main-body{
    .main-content{
      width: 100%;
      margin-left: 0px;
    }
  }

}

@media only screen and (max-width: 767px) {

  //main-body
  .main-body{
    .main-content{
      .main-header{
        .header-top-search{
          margin-bottom: 15px;
        }
      }
      .main-footer{
        text-align: center;
      }
    }
  }
  //vendor-table
  .vendor-table{
    .vendor-table-head{
      .vendor-table-head-right{
        justify-content: flex-start;
        .vendor-table-search{
          margin-bottom: 5px;
        }
      }
    }
  }

}

@media screen and (max-width: 1024px) and (min-width: 768px){

  //main-body
  .main-body{
    .main-content{
      .main-header{
        .header-top-left{
          .header-top-search{
            margin-bottom: 15px;
          }
        }

      }
      .main-footer{
        text-align: center;
      }
    }
  }

}


@media only screen and (max-width: 1024px){
   //main-body
   .main-body{
    .main-content{
      .main-header{
        .header-top-left{
          .toggle-btn{
            display: block;
          }
        }
      }
    }
  }
}

.admin-card{
  .admin-card-box{
    .patient-heading{
      h5{
        color: #098180;
        font-size: 20px;
      }
    }
    .patient-info{
      display: flex;
      flex-direction: column;
      .value{
        font-size: 14px;
        color: #444;
        font-weight: bold;
        &:not(:first-child) {
          margin: 10px 0px;
        }
      }
    }

    .report-header{
      padding: 10px 0;
      background: #80808047;
      border-bottom: 1px solid #444;
    }

    .report-container{
      .selected-file{
        .file-name{
          padding: 10px;
          border: 1px solid #444;
          display: flex;
          align-items: center;
          flex-direction: row;
          justify-content: space-between;
          i{
            color: #800020;
            border: 1px solid #800020;
            padding: 5px 7px;
            border-radius: 50%;
            cursor: pointer;
            margin-left: 3px;
          }
        }
      }
    }
  }
}
