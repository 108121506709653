.nav-tabs {
  .nav-item{
    &.active {
      background-color: #098180b3;
    }
    &.nav-link{
      color: #495057;
    }
  }
}

.vendor-table-body{
  .table{
    tbody{
      tr{
        td{
          .active{
            background-color: #cbf4c9;
            color: #0e6245;
            padding: 2px;
          }
          .inactive{
            background-color: #f0d6d0;
            color: #d93110;
            padding: 2px;
          }
        }
      }
    }
  }
}

.fa.fa-print{
  opacity: 0.5;
  cursor: pointer;
  &:hover{
    opacity: 1;
  }
}

.PhoneInput{
  .PhoneInputCountry{
    display: none;
  }
  .PhoneInputInput{
    margin-bottom: 0;
    &:focus{
      background-color: #fff;
      outline: 0;
    }
    border: 1px solid #D1D1D1;
    height: 40px;
    border-radius: 0px;
    font-size: 13px;
    padding: .375rem .75rem;
  }
}
.fa-file-export{
  cursor: pointer;
  font-size: 18px;
}
