.admin-card{
  .admin-card-box{
    .vendor-table{
      .address{
        margin-bottom: 10px;
        .card {
          box-shadow: 2px 2px #e5e5e5;
          float: left;
          width: 30%;
          margin: 5px 10px 20px 5px;
          min-height: 280px;
          .card-header {
            h4{
              font-size: 16px;
              font-weight: bold;
              letter-spacing: 1px;
              margin: 0;
            }
          }
          .card-body{
            .item{
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              .label{
                font-size: 14px;
                font-weight: bold;
              }
              .value{
                font-size: 14px;
                color: #444;
              }
            }
          }
        }
      }
      .shipment{
        .card{
          box-shadow: 2px 2px #e5e5e5;
          position: relative;
          border: 1px solid #bbb;
          width: 250px;
          background: #eee;
          float: left;
          margin: 0 20px;
          .ribbon {
            position: absolute;
            right: -5px;
            top: -5px;
            z-index: 1;
            overflow: hidden;
            width: 93px;
            height: 93px;
            text-align: right;
          }
          .ribbon span {
            font-size: 0.8rem;
            color: #fff;
            text-transform: uppercase;
            text-align: center;
            font-weight: bold;
            line-height: 32px;
            transform: rotate(45deg);
            width: 125px;
            display: block;
            box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
            position: absolute;
            top: 17px; // change this, if no border
            right: -29px; // change this, if no border
          }

          .ribbon span::before {
            content: '';
            position: absolute;
            left: 0px; top: 100%;
            z-index: -1;
            border-left: 3px solid #79A70A;
            border-right: 3px solid transparent;
            border-bottom: 3px solid transparent;
            border-top: 3px solid #79A70A;
          }
          .ribbon span::after {
            content: '';
            position: absolute;
            right: 0%; top: 100%;
            z-index: -1;
            border-right: 3px solid #79A70A;
            border-left: 3px solid transparent;
            border-bottom: 3px solid transparent;
            border-top: 3px solid #79A70A;
          }

          .green span {
            background: #79a70a;
            background: linear-gradient(#9bc90d 0%, #79a70a 100%);
          }
          .green span::before {
            border-left-color: #79A70A;
            border-top-color: #79A70A;
          }
          .green span::after {
            border-right-color: #79A70A;
            border-top-color: #79A70A;
          }

          .orange span {
            background: linear-gradient(#ef7847db 0%, #f8672b 100%);
          }
          .orange span::before {
            border-left-color: #f8672b;
            border-top-color: #f8672b;
          }
          .orange span::after {
            border-right-color: #f8672b;
            border-top-color: #f8672b;
          }

          .blue span {
            background: linear-gradient(#2989d8 0%, #1e5799 100%);
          }
          .blue span::before {
            border-left-color: #1e5799;
            border-top-color: #1e5799;
          }
          .blue span::after {
            border-right-color: #1e5799;
            border-top-color: #1e5799;
          }
          .card-body{
            .carrier-logo{
              border: 0;
              width: 75px;
            }
            .details{
              padding: 5px 15px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              .label{
                font-size: 14px;
              }
              .value{
                font-size: 14px;
              }
            }
            .additional-details{
              font-size: 14px;
              text-align: justify;
              color: #444444e3;
              background-color: #80808026;
              padding: 5px 7px;
              span{
                line-height: 1.25em;
              }
            }
          }
        }
      }
    }
  }
  .modal{
    .modal-content{
      background-color: #eee;
    }
    .modal-header{
      .purchase-loader{
        position: absolute;
        right: 48%;
        bottom: 5%;
      }
      h4{
        font-size: 20px;
      }
    }
  }
}
