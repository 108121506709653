.kit-id-label{
  padding: 2px;
  .logo{
    border: 0;
    width: 115px;
    height: 20px;
  }
  .content{
    font-size: 18px;
  }
}
