@import "../../Styles/variables.scss";
/* .modal {
  position: fixed !important;
  margin: 0 !important;
  .modal-dialog { */
      .tnc-condition-link{
     font-size: 13px;
     font-weight: 600;
   }
    .signin-page {
      .signup-desc {
        background-color: #f3ecfc;
        height: 100%;
        display: flex;
        flex-flow: column;
        justify-content: center;
        h2 {
          padding-top: 50px;
          text-align: center;
          color: $color-primary;
          span {
            font-weight: $main-font-weight;
          }
        }
        .signin-img {
          padding: 20px;
          img {
            width: 100%;
          }
        }
      }
/*       .close-p {
        width: 100%;
        height: 50px;
        .close {
          font-size: 35px;
          padding-right: 10px;
          color: $color-primary;
          cursor: pointer;
        }
      } */
      .form-sigin {
        position: relative;
        .form-nav {
          width: 60%;
          justify-content: 0 auto;
          border: 1px solid $color-primary;
          border-radius: 100px;
          overflow: hidden;
          margin: 0 auto;
          margin-top: 50px;
          .nav-tabs {
            width: 100%;
            a {
              text-transform: uppercase;
              color: $color-primary;
              width: 80px;
              &.active {
                border-radius: 100px;
                background-color: $color-primary;
                border: 1px solid $color-primary;
                color: $color-white;
                width: 80px;
              }
              &:hover {
                border: 1px solid transparent;
              }
            }
          }
        }
        .tab-content {
          .tab-pane {
            padding: 20px;
            h4 {
              color: $color-primary;
              font-weight: $main-font-weight;
              text-align: center;
            }
            p {
              text-align: center;
              font-size: 14px;
              color: $color-primary-fade;
              margin-bottom: 30px;
            }
            .coming-soon {
              text-align: center;
              color: $color-primary;
              h2 {
                font-weight: $main-font-weight;
              }
            }
            form {
              display: flex;
              flex-flow: column;
              align-items: center;
              margin-bottom: 15px;
              .form-group {
                text-align: center;
                input {
                  width: 280px;
                  height: 45px;
                  border-radius: 100px;
                  border: 1px solid $color-primary;
                }
                span{
                  font-size: 14px;
                }
              }
              .button-div {
                /* width: 100%; */
                button {
                  background-color: #fe8264;
                  border: 1px solid #fe8264;
                  border-radius: 100px;
                  color: #fff;
                  text-transform: uppercase;
                  font-weight: $main-font-weight;
                  font-size: 15px;
                  width: 150px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  height: 50px;
                  text-decoration: none;
                  /*  margin-left: 30px; */
                  margin-top: 10px;
                  &:hover{
                    box-shadow: 0px 3px 3px #fe826459;
                  }
                }
              }
            }
            .first-p {
              font-size: 18px;
              color: $color-primary-fade;
              p {
                margin-bottom: 10px;
                font-weight: normal;
              }
            }
            .social-icon {
              display: flex;
              justify-content: center;
              align-items: center;
              flex-wrap: wrap;
              .google-icon {
                width: 130px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid $color-primary;
                border-radius: 100px;
                margin: 5px;
                cursor: pointer;
                span {
                  font-size: 25px;
                  color: $color-primary;
                }
                p {
                  margin-bottom: 0;
                  color: $color-primary-fade;
                  font-size: 13px;
                  font-weight: normal;
                  margin-left: 5px;
                }
              }
            }
            .second-p {
              font-size: 18px;
              color: $color-primary-fade;
              margin-top: 10px;
              p {
                margin-bottom: 0;
                font-weight: normal;
                span {
                  font-weight: $main-font-weight;
                  color: $color-primary;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }

    .modal-blog{
      .modal-content{
        height: 90vh;
        overflow: auto;
        border-radius: 0px;
        .modal-header{
          position: sticky;
          top: 0;
          background-color: #fff;
          z-index: 1;
        }
      }
    }

    .modal-body {
      .custom-check {
        text-align: center;
        .checkmark {
          margin: 0 auto;
          text-align: center;
          left: -135px;
          right: 0;
        }
      }
      .button-center {
        text-align: center;
        button {
          margin: 10px;
        }
      }
    }
/*   }
} */

//Media Queries
@media screen and (max-width: 767px) {
  .signin-page {
    padding-right: 0;
    .form-nav{
      width: 290px !important;
      .nav-tabs{
        width: auto !important;
      }
    }
  }
}

@media (max-width: 991px) and (min-width: 768px) {
      .signin-page {
        .form-sigin{
          .form-nav{
            width: 200px !important;
            .nav-tabs{
              width: auto !important;
            }
          }
          .tab-content{
            .tab-pane{
              form{
                .form-group{
                  input{
                    //width: auto !important;
                  }
                }
              }
            }
          }
        }
      }
}