//track-order-box
.track-order-box{
    background-color: #FFFFFF;
    padding: 15px;
    border: 0.5px solid #ced4da;
    margin-bottom: 20px;
    ul{
        display: flex;
        justify-content: space-between;
        margin: 0px;
        padding: 0px;
        position: relative;
        &:after{
            content: "";
            position: absolute;
            top: 35px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: #989898;
            z-index: 1;
        }
        li{
            text-align: center;
            list-style: none;
            display: flex;
            flex-flow: column;
            align-items: center;
            background-color: #fff;
            position: relative;
            z-index: 2;
            width: 110px;
            .track-order-icon{
                min-width: 70px;
                height: 70px;
                width: 70px;
                background-color: #fff;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 10px;
                border: 1px solid #989898;
                i{
                    color: #989898;
                    font-size: 25px;
                }
            }
            p{
                color: #000000;
                font-size: 13px;
                margin-bottom: 0px;
                font-weight: 500;
                text-transform: uppercase;
                margin-left: 5px;
            }
            &.active{
                .track-order-icon{
                    border-color: #E25E52;
                    background-color: #E25E52;
                    i{
                        color: #fff;
                    }
                }
                
            }
        }
    }
}

//vendor-table
.vendor-table{
    .vendor-table-body{
        margin-bottom: 20px;
        .table{
            thead{
                tr{
                    td{
                        font-weight: 500;
                        color: #000;
                    }
                }
            }
            tbody{
                tr{
                    td{
                        .track-time{
                            p{
                                margin-bottom: 0px;
                                font-weight: 600;
                                span{
                                    min-width: 12px;
                                    height: 12px;
                                    width: 12px;
                                    background-color: #E25E52;
                                    border-radius: 100%;
                                    display: inline-block;
                                    margin-right: 8px;
                                    margin-bottom: -2px;
                                }
                            }
                        }
                        .track-status{
                            p{
                                color: #000000;
                                margin-bottom: 0px;
                                text-transform: initial;
                                font-weight: normal;
                            }
                        }
                    }
                }
            }
        }
    }

    .vendor-table-bottom{
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

//media
@media only screen and (max-width: 767px) {
    //track-order-box
    .track-order-box{
        padding: 10px;
        ul{
            &:after{
                top: 17px;
            }
            li{
                max-width: 60px;
                width: 20%;
                .track-order-icon{
                    min-width: 35px;
                    height: 35px;
                    width: 35px;
                    i{
                        font-size: 12px;
                    }
                }
                p{
                    font-size: 10px;
                    margin-left: 0px;
                }
            }
        }
    }
}