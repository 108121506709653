@import "../Styles/variables.scss";

//sidebar
.sidebar{
  width: 250px;
  background-color: #fff;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
  &.sidebar-show{
    display: block;
  }
  .sidebar-head{
    padding: 25px;
    text-align: center;
    border-bottom: 1px solid #ced4da;
    position: relative;
    img{
      width: 100%;
      max-width: 150px;
    }
    .toggle-close-btn{
      display: none;
      button{
        border: 0px;
        background-color: transparent;
        color: #000;
        padding: 5px 10px;
        font-size: 22px;
        position: absolute;
        top: 0;
        right: 0;
      }
    }

  }
  .sidebar-menu{
    padding-top: 10px;
    height: calc(100vh - 200px);
    overflow: auto;
    width: 110%;
    ul{
      margin: 0px;
      padding: 0px;
      li{
        list-style: none;
        a{
          color: #000000;
          background-color: #fff;
          display: block;
          padding: 10px 15px;
          text-transform: uppercase;
          font-size: 12px;
          font-weight: 500;
          border-right: 3px solid transparent;
          position: relative;
          i{
            margin-right: 10px;
          }
          &:hover, &.active{
            background-color: #52e2781a;
            color: $color-main;
            border-right-color: $color-main;
          }
        }
        span {
          border: 100px;
          background-color: $color-main;
          font-size: 10px;
          position: absolute;
          top: 10px;
          right: 30px;
          color: #fff;
          border-radius: 100%;
          min-width: 20px;
          min-height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  //sidebar
  .sidebar{
    display: none;
    .sidebar-head{
      .toggle-close-btn{
        display: block;
      }
    }
  }
}
